import React from "react"
import Helmet from "react-helmet"
import { graphql, Link } from "gatsby"
import Layout from "../layout"
import config from "../../data/SiteConfig"
import ReferenceItems from "../components/ReferenceItems/ReferenceItems"
import Hero from "../components/Hero/Hero"
import GameListing from '../components/GameListing/GameListing'
import GameNav from '../components/GameNav/GameNav'
import NewsListing from '../components/NewsListing/NewsListing'
import BetSiteListing from '../components/BetSiteListing/BetSiteListing'
import BetSiteListing2 from '../components/BetSiteListing2/BetSiteListing2'
import BetBonusListing from '../components/BetBonusListing/BetBonusListing'
import Faq from "../components/Faq/Faq"
import InfoBlocks from "../components/InfoBlocks/InfoBlocks"

class Index extends React.Component {
  render () {
    const news = this.props.data.allPrismicNewsItem.edges
    const games = this.props.data.allPrismicGame.edges
    const hottestGames = this.props.data.allPrismicGame.edges.slice(0, 3)
    const sites = this.props.data.allPrismicBetsite.edges
    const featuredSites = sites.slice(0, 2)
    let indexContent = this.props.data.prismicIndex.data
    return (
      <Layout>
        <div className="index-container">
        <Helmet>
                <title>{`${config.siteTitle}`}</title>
                <meta name="description" content={config.siteDescription} />
                <meta name="image" content={config.siteLogo} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:url" content={config.siteUrl} />
                <meta property="og:title" content={config.siteTitle} />
                <meta property="og:description" content={config.siteDescription} />
                <meta property="og:image" content={config.siteLogo} />
                {/* <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ""}/> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ""}/>
                <meta name="twitter:title" content={config.siteTitle} />
                <meta name="twitter:description" content={config.siteDescription} />
                <meta name="twitter:image" content={config.siteLogo} />
                <script type="application/ld+json" id="website-json-ld">
                  {JSON.stringify(
                    {
                      "@context":"http://schema.org",
                      "@type":"WebSite",
                      "name":"Esportstips",
                      "alternateName":"esportstips.net",
                      "url":"https://esportstips.net",
                      "@context":"http://schema.org",
                      "@type":"Organization",
                      "name":"Esportstips",
                      "url":"https://esportstips.net",
                      "logo":config.siteLogo,
                      // "sameAs":[
                      //     "https://www.facebook.com/esportstips",
                      //     "https://www.instagram.com/esportstips/",
                      //     "https://twitter.com/esportstips",
                      // ]
                    }
                  )}
                </script>
            </Helmet>
          <Hero />
          <GameNav games={games} className="game-listing"/>
          <section className="section">
            <div className="bg-tint-3"></div>
            <div className="container">

              <div className="columns">
                <div className="column">
                    <h2 className="subtitle">
                      Esports betting
                    </h2>
                    <p>
                    <b>The best esports betting sites and bonuses!</b> Esportstips always provides you with the latest esports data to let you make
                    the best esports bets. Claim the best esports betting bonuses to maximize your withdrawals in esports betting. Follow our news to stay updated about relevant news in the esports scene to make the best
                    esports bets online! We truly believe esports gamblers are entitled to the best possible options for esports betting and betting bonuses.
                    </p>
                    <Link to="/esports-betting">
                      <button className="button is-success is-small">Getting started on esports betting</button>
                    </Link>
                </div>
                <div className="column">
                  <BetSiteListing sites={featuredSites} className="bet-site-listing" />
                </div>
              </div>
            </div>
          </section>
          <section className="section content-section">
            <div className="container">
              <h3 className="subtitle">Tips on esports gambling and the best esports odds</h3>
              <div className="success">
                <p>
                  We provide news and tips about esports betting and gambling to aid you in placing esports bets. We have gathered
                  an extensive curated list of the best esports betting sites available for esports gamblers. With our help it is easy to find
                  a suitable esports betting site with the best bonuses and promotional offers for you. It is also easy to browse the best online esports 
                  betting sites and the register bonuses they offer with our esports betting site reviews. You should always look for the best possible register bonuses on esports betting sites 
                  to maximize your cash withdrawals on online esports betting providers. 
                </p>
                <p>
                  We also cover each esports betting sites' deposit and withdrawal methods. For example we list all the possible 
                  transaction options available for a particular esports betting site, such as Skrill, PayPal, Neteller, Mastercard etc. 
                  Our esports betting site reviews are not only limited to these factors. We are constantly updating and reviewing new esports betting sites 
                  to provide esports gamblers and players the best possible options for esports betting.
                </p>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="container">
              <BetBonusListing sites={sites} className="bet-site-listing" />
            </div>
          </section>
          <section className="section">
            <div className="container">
              <BetSiteListing2 sites={sites} className="bet-site-listing" />
            </div>
          </section>
          <section className="section">
                <div className="container">
                  <h4 className="subtitle">Latest esports news</h4>
                  <p>Keeping up to date with esports news is an important factor when it comes down to online esports betting. 
                    Without the proper up to date knowledge of the vast esports scene it can be hard to make tactical esports bets on 
                    hot and current esports betting markets. By staying up to date with esports betting news you will never miss an opportunity to win 
                    money with esports betting.
                  </p>
                  <NewsListing news={news}/>

                </div>
          </section>
          <section className="section content-section">
            <div className="container">
              <h3 className="subtitle">
                { indexContent.content_blocks[0].content_title.text }
              </h3>
              <div dangerouslySetInnerHTML={{ __html: indexContent.content_blocks[0].content_text.html }} />
            </div>
          </section>
          <section className="section content-section">
              <div className="container">
                <h4 className="subtitle">Hottest esports games</h4>
                <GameListing games={hottestGames} />
              </div>
          </section>
          <section className="section content-section">
            <div className="container">
              <h3 className="subtitle">
                { indexContent.content_blocks[1].content_title.text }
              </h3>
              <div dangerouslySetInnerHTML={{ __html: indexContent.content_blocks[1].content_text.html }} />
            </div>
          </section>
          <section className="section content-section">
            <div className="container">
              <InfoBlocks infoItems={indexContent.info_blocks} />
            </div>
          </section>
          <section className="section content-section">
            <div className="container">
              <h2 className="subtitle">
                { indexContent.content_blocks[2].content_title.text }
              </h2>
              <div dangerouslySetInnerHTML={{ __html: indexContent.content_blocks[2].content_text.html }} />
            </div>
          </section>
          <section className="section content-section">
            <div className="container">
              <h2 className="subtitle">
                { indexContent.content_blocks[3].content_title.text }
              </h2>
              <div dangerouslySetInnerHTML={{ __html: indexContent.content_blocks[3].content_text.html }} />
            </div>
          </section>
          <section className="section content-section">
            <div className="container">
              <h2 className="subtitle">
                { indexContent.content_blocks[4].content_title.text }
              </h2>
              <div dangerouslySetInnerHTML={{ __html: indexContent.content_blocks[3].content_text.html }} />
            </div>
          </section>
          <section className="section">
            <div className="container">
              <Faq faqItems={indexContent.faq} />
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query IndexQuery {
  	allPrismicNewsItem (
    	limit: 6
    	sort: {
      	fields: [data___date]
      	order: DESC
  		}) {
        edges {
          node {
            uid
            data {
              title {
                text
              }
              description {
                html
              }
              date
              key_tag
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicGame (limit: 5) {
        edges {
          node {
            uid
            data {
              name {
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              logo {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicBetsite(limit: 6) {
        edges {
          node {
            uid
            data {
              name {
                text
              }
              website {
                url
              }
              bonus
              rating
              brand_color
              terms_and_conditions_link {
                url
              }
              logo {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      prismicIndex {
        data {
          faq {
            faq_title {
              text
            }
            faq_content {
              text
            }
          }
          info_blocks {
            info_block_title {
              text
            }
            info_block_content {
              html
            }
            info_block_image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          content_blocks {
            content_title {
              text
            }
            content_text {
              html
            }
          }
        }
      }

  }
`;
